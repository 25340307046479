<template>
	<v-row>
		<v-dialog v-model="dialog.status" max-width="575" persistent>
			<CardDialog :dialog="dialog" />
		</v-dialog>
		<v-col cols="12" md="7">
			<v-card>
				<v-card-title>Novo chamado</v-card-title>
				<v-divider />
				<v-card-text>
					<v-textarea
						outlined
						label="Descrição do chamado"
						rows="10"
						class="mb-n7"
						:loading="carregando"
						v-model="chamado.descricao"
					></v-textarea>
				</v-card-text>
				<v-card-actions class="mt-n5">
					<v-spacer />
					<v-btn class="mr-1 mr-md-3" text color="primary" elevation="0" href="../../AnyDesk-5-3-3.exe">
						<v-icon class="mr-1" size="16">mdi-download</v-icon>AnyDesk
					</v-btn>
					<v-btn text color="primary" elevation="0" href="../../AA_v3.exe">
						<v-icon class="mr-1" size="16">mdi-download</v-icon>Ammyy Admin
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
		<v-col cols="12" md="5">
			<v-card>
				<v-card-title>Resumo do chamado</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row align="end">
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Nome chamado</div>
							<v-text-field class="mb-n10" v-model="chamado.chamado" outlined dense></v-text-field>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Departamento</div>
							<v-select
								class="mb-n10"
								:items="departamentos.lista"
								item-text="departamento"
								item-value="iddepartamento"
								:loading="carregando"
								outlined
								dense
								v-model="chamado.iddepartamento"
								@change="listarCategorias()"
							></v-select>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Categoria</div>
							<v-select
								class="mb-n10"
								:items="categorias.lista"
								item-text="categoria"
								item-value="idcategoria"
								:loading="carregando"
								outlined
								dense
								v-model="chamado.idcategoria"
								@change="listarSubCategorias()"
							></v-select>
						</v-col>
						<v-col cols="12" v-if="subCategorias.lista.length > 0">
							<div class="text-body-1 font-weight-bold">Sub categoria</div>
							<v-select
								class="mb-n10"
								:items="subCategorias.lista"
								item-text="subcategoria"
								item-value="idsubcategoria"
								:loading="carregando"
								outlined
								dense
								v-model="chamado.idsubcategoria"
							></v-select>
						</v-col>
						<v-col cols="12">
							<v-row class="text-body-1 font-weight-bold mb-n7 mt-0">
								<v-col cols="auto" class="d-flex">
									AnyDesk
									<small class="red--text ml-2">(Manter conexão remota aberta)</small>
									<MenuInfo
										color="red"
										texto="Campo obrigatório. Lembre-se de deixar seu AnyDesk aberto. Informe para que seu chamado possa ser solucionado de forma mais rápida."
									/>
								</v-col>
								<v-spacer></v-spacer>
								<v-col cols="auto" class="d-flex ml-auto">
									<span class="text-caption font-weight-bold">Não tenho AnyDesk</span>
									<v-switch dense class="ml-3 my-0 py-0" color="red" v-model="chamado.anydesk"></v-switch>
								</v-col>
							</v-row>
							<v-text-field class="mb-n10" v-model="chamado.idconexaoremota" outlined dense></v-text-field>
						</v-col>
						<template
							v-if="usuario.iddepartamento == chamado.iddepartamento && chamado.iddepartamento != '' && (usuario.idfilial == 1 || usuario.idfilial == 13)"
						>
							<v-col cols="12">
								<div class="text-body-1 font-weight-bold">Filial</div>
								<v-autocomplete
									class="mb-n10"
									v-model="chamado.idfilial"
									outlined
									dense
									:items="filiaisFiltro"
									:loading="carregando"
									item-text="filial"
									item-value="idfilial"
									@change="listarUsuarios()"
								></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<div class="text-body-1 font-weight-bold">Solicitante</div>
								<v-autocomplete
									class="mb-n10"
									v-model="chamado.idusuario"
									outlined
									dense
									:items="usuarios.lista"
									:loading="carregando"
									item-text="usuario"
									item-value="idusuario"
								></v-autocomplete>
							</v-col>
						</template>
						<template>
							<v-col cols="12" class="pb-0">
								<div class="text-body-1 font-weight-bold">Anexar arquivo</div>
								<v-file-input
									counter
									multiple
									show-size
									outlined
									dense
									prepend-icon
									prepend-inner-icon="mdi-paperclip"
									@change="adicionarArquivo"
								></v-file-input>
							</v-col>
						</template>
					</v-row>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-row>
						<v-col cols="12" class="mb-n3">
							<v-btn
								@click="abrirChamado()"
								color="primary"
								block
								elevation="0"
								:disabled="carregando"
							>Abrir chamado</v-btn>
						</v-col>
						<v-col cols="12">
							<v-btn
								class="text-decoration-underline"
								block
								elevation="0"
								color="primary"
								to="/chamado"
								text
								small
								exact
								:disabled="carregando"
							>Voltar</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import CardDialog from "../Widgets/CardDialog";
import MenuInfo from "../Widgets/MenuInfo.vue";

export default {
	name: "ChamadoNovo",
	mixins: [mixinFilial],
	components: { CardDialog, MenuInfo },
	data: () => ({
		dialog: { status: false },
		carregando: false,
		chamado: {
			chamado: "",
			descricao: "",
			idcategoria: "",
			idsubcategoria: "",
			iddepartamento: "",
			idconexaoremota: "",
		},
		usuarios: { lista: [] },
		departamentos: { lista: [] },
		categorias: { lista: [] },
		subCategorias: { lista: [] },
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.map((v) => {
					const filial = {};
					filial.idfilial = v.idfilial;
					filial.filial = `${v.idfilial} - ${v.filial}`;
					return filial;
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		abrirChamado() {
			if (
				this.chamado.chamado == "" ||
				this.chamado.descricao == "" ||
				this.chamado.idcategoria == "" ||
				this.chamado.iddepartamento == "" ||
				(this.subCategorias.lista.length > 0 &&
					this.chamado.idsubcategoria == "")
			) {
				this.dialog.status = true;
				this.dialog.title = "Erro";
				this.dialog.msg =
					"Um ou mais campos não foram preenchidos, preencha todos campos antes de prosseguir!";
				this.dialog.icon = "mdi-alert-circle-outline";
				this.dialog.iconColor = "red";
				return this.dialog;
			}
			if (this.chamado.idconexaoremota == "" && !this.chamado.anydesk) {
				this.dialog.status = true;
				this.dialog.title = "Erro";
				this.dialog.msg =
					"O campo de conexão remota não foi informado!";
				this.dialog.icon = "mdi-alert-circle-outline";
				this.dialog.iconColor = "red";
				return this.dialog;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/abrir`, {
					chamado: this.chamado.chamado,
					descricao: this.chamado.descricao.replaceAll("\n", "/ln"),
					idcategoria: this.chamado.idcategoria,
					idsubcategoria: this.chamado.idsubcategoria || null,
					iddepartamento: this.chamado.iddepartamento,
					idusuario: this.chamado.idusuario || null,
					idfilial: this.chamado.idfilial || null,
					idconexaoremota: this.chamado.idconexaoremota || null,
					arquivo: this.chamado.arquivo || null,
				})
				.then((res) => {
					if (typeof res.data === "number") {
						return this.$router.push(`get/${res.data}`);
					}
					this.dialog.status = true;
					this.dialog.title = "Erro";
					this.dialog.msg = "Não foi possível abrir o chamado!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listarUsuarios() {
			this.usuarios.lista = [];
			return axios
				.post(`${this.backendUrl}usuario/listar`, {
					limit: 99999,
					offset: 0,
					notidgrupo: 3,
					idfilial: this.chamado.idfilial || null,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.usuarios = res.data;
						this.usuarios.lista = this.usuarios.lista.filter(
							(v) => v.ativo == "F"
						);
					}
				});
		},
		listarDepartamentos() {
			this.departamentos.lista = [];
			return axios
				.post(`${this.backendUrl}departamento/listar`, {
					limit: 9999,
					offset: 0,
					busca: null,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.departamentos = res.data;
					}
				});
		},
		listarCategorias() {
			this.categorias.lista = [];
			return axios
				.post(`${this.backendUrl}chamado/categoria`, {
					iddepartamento: this.chamado.iddepartamento,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.categorias = res.data;
					}
				});
		},
		listarSubCategorias() {
			this.subCategorias.lista = [];
			this.chamado.idsubcategoria = "";
			return axios
				.post(`${this.backendUrl}chamado/subcategoria`, {
					idcategoria: this.chamado.idcategoria,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.subCategorias = res.data;
					}
				});
		},
		adicionarArquivo(arquivos) {
			this.carregando = true;
			this.chamado.arquivo = [];
			let a = [];
			for (let i = 0; i < arquivos.length; i++) {
				let reader = new FileReader();
				reader.readAsDataURL(arquivos[i]);
				reader.onload = function () {
					a[i] = {
						arquivo: reader.result.split("base64,")[1],
						arquivoname: arquivos[i].name,
					};
				};
			}
			this.chamado.arquivo = a;
			this.carregando = false;
		},
		async init() {
			await this.listarDepartamentos();
		},
	},
	created() {
		this.init();
	},
};
</script>